<template>
  <div class="container">
    <div class="row mt-3 mb-4">
      <div class="col-8">
        <h3>
          <i class="el-icon-s-comment"></i> Sipariş Hareketleri
        </h3>
      </div>
      <div class="col-4 text-right">
        <button type="button" class="btn btn-sm" aria-label="Close" @click="$emit('close')">
          <i class="el-icon-close"></i>
        </button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <div class="card text-white bg-info">
          <div class="card-header">
            <strong>SİPARİŞ BİLGİLERİ</strong>
          </div>
          <div class="card-body">
            <strong>Sipariş No:</strong>
            {{orderDetail.id}}&nbsp;&nbsp;&nbsp;
            <strong>İşlem Zamanı:</strong>
            {{orderDetail.islemtarihi}}
            <br />
            <strong>Ödeme Şekli:</strong>
            {{orderDetail.odemetipi}} - {{orderDetail.odenecektutar}} TL / {{orderDetail.odemenereye}}
            <br />
            <strong>Fatura Gönderim:</strong>
            {{orderDetail.faturateslimsekli}}&nbsp;&nbsp;&nbsp;
            <strong>Ürün:</strong>
            {{orderDetail.urunkodu}} / {{orderDetail.urunboy}}
            <br />
            <strong>Durumu:</strong>
            {{orderDetail.siparisdurumu}}&nbsp;&nbsp;&nbsp;
            <strong>Takip Kodu:</strong>
            {{orderDetail.takipno}}
            <br />
            <br />
            <strong>Müşteri:</strong>
            {{orderDetail.adisoyadi}} / {{orderDetail.telno}} / {{orderDetail.email}}
            <br />
            <br />
            <strong>Alıcı:</strong>
            {{orderDetail.aliciadisoyadi}} / {{orderDetail.alicitelno}}
            <br />
            <strong>Adres:</strong>
            {{orderDetail.gonderilecekyeradi}} - {{orderDetail.aliciadres}}
            <br />
            <strong>Semt / Şehir:</strong>
            &nbsp;
            <span
              class="text-warning"
            >{{orderDetail.alicisemt}} / {{orderDetail.alicisehir}}</span>
            <br />
            <strong>Teslim Alan:</strong>
            {{orderDetail.teslimedilen}}
          </div>
        </div>
        <div class="card bg-secondary mt-3" v-if="orderActions.length > 0">
          <div class="card-header text-white">
            <strong>SİPARİŞ İLE İLGİLİ HAREKETLER ({{orderActions.length}})</strong>
          </div>
          <div class="card-body">
            <div v-for="action in orderActions" :key="action.id" class="card mt-1">
              <div class="card-header">
                <div class="row">
                  <div class="col-6">
                    <strong>
                      <i class="el-icon-date"></i>
                      {{action.tarih}} &nbsp;&nbsp;&nbsp;
                      <i
                        class="el-icon-user"
                      ></i>
                      {{action.kullanici}}
                    </strong>
                  </div>
                  <div class="col-6 text-right text-info" v-if="action.notifycustomer == 1">
                    <i class="el-icon-success"></i> Müşteri Bilgilendirildi
                  </div>
                </div>
              </div>
              <div class="card-body">{{action.mesaj}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="form-group">
          <textarea
            class="form-control"
            rows="3"
            placeholder="Yeni sipariş hareketi eklemek için buraya yazabilirsiniz..."
            v-model="newAction"
          ></textarea>
        </div>
        <div class="form-group form-check">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="notifyCustomer"
            id="notifyCustomer"
          />
          <label class="form-check-label" for="notifyCustomer">
            <strong>Müşteriyi Bilgilendir</strong> (Gönderici'ye SMS ve E-Mail Gönderilir)
          </label>
        </div>
        <div class="form-group text-center">
          <button @click="addAction" type="button" class="btn btn-primary">
            <i class="el-icon-circle-plus-outline"></i> HAREKET EKLE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    userID: localStorage.getItem("userID"),
    userHash: localStorage.getItem("userHash"),
    orderDetail: {},
    orderActions: {},
    newAction: "",
    notifyCustomer: false
  }),
  props: ["orderID", "orderUrl"],
  methods: {
    async addAction() {
      const mesaj = JSON.stringify({
        id: this.orderID,
        mesaj: this.newAction,
        notifyCustomer: this.notifyCustomer
      });
      await axios
        .post(
          "https://" +
            this.$props.orderUrl +
            "/panelapi/actionupdate?userID=" +
            this.userID +
            "&userHash=" +
            this.userHash,
          mesaj
        )
        .then(response => {
          if (response.data.sonuc === "success") {
            this.getOrderActions();
            this.newAction = "";
            this.notifyCustomer = false;
          } else {
            this.$message({
              type: "error",
              message: response.data.msg
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "Hata oluştu. Yeniden deneyiniz."
          });
        });
    },
    async getOrderDetail() {
      await axios
        .get("https://" + this.$props.orderUrl + "/panelapi/orderdetail", {
          params: {
            userID: this.userID,
            userHash: this.userHash,
            orderID: this.$props.orderID
          }
        })
        .then(response => {
          this.orderDetail = response.data;
        });
    },
    async getOrderActions() {
      await axios
        .get("https://" + this.$props.orderUrl + "/panelapi/orderactions", {
          params: {
            userID: this.userID,
            userHash: this.userHash,
            orderID: this.$props.orderID
          }
        })
        .then(response => {
          this.orderActions = response.data;
        });
    }
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: "Yükleniyor",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.5)"
    });
    Promise.all([this.getOrderDetail(), this.getOrderActions()]).then(() => {
      loading.close();
    });
  }
};
</script>