<template>
  <router-view />
</template>

<script>
export default {};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap");
a {
  text-decoration: none;
  color: #4f4f4f;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
  color: #c00;
  cursor: pointer;
}
body {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.btn,
.siparisdurumuicon {
  cursor: pointer;
}
.siparisdurumuiconactive {
  font-size: 1.6em;
}
.homeNumberBar {
  color: #fff;
}
.homeNumberBar a {
  color: #fff;
}
.homeNumberBar a:hover {
  cursor: pointer;
}
.header-box {
  padding: 20px;
  color: #fff;
}
.header-box .number {
  font-size: 3em;
  font-weight: 700;
}
</style>
