<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <nav class="navbar navbar-expand-xl navbar-dark bg-dark">
          <router-link :to="{ name: 'Dashboard' }" class="navbar-brand"
            >Sipariş Paneli</router-link
          >
          <el-dropdown trigger="click" :hide-on-click="false">
            <span class="navbar-toggler navbar-toggler-icon"></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-input
                  placeholder="Sipariş Arama"
                  v-model="keyword"
                  prefix-icon="el-icon-search"
                >
                  <el-button slot="append" @click="generalSearch()"
                    >ARA</el-button
                  >
                </el-input>
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-link">
                <a
                  :href="
                    'https://markacicek.com/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                  >Marka Çiçek</a
                >
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-link">
                <a
                  :href="
                    'https://cicekatolyesi.com/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                  >Çiçek Atölyesi</a
                >
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-link">
                <a
                  :href="
                    'https://harman.com.tr/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                  >Harman</a
                >
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-link">
                <a
                  :href="
                    'https://celenkatolyesi.com/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                  >Çelenk Atölyesi</a
                >
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-user">
                <a @click="logout">Çıkış</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="
                    'https://markacicek.com/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                >
                  <i class="el-icon-link"></i> Marka Çiçek
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="
                    'https://cicekatolyesi.com/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                >
                  <i class="el-icon-link"></i> Çiçek Atölyesi
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="
                    'https://harman.com.tr/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                >
                  <i class="el-icon-link"></i> Harman
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="
                    'https://celenkatolyesi.com/yonetim/index.php?userID=' +
                    userID +
                    '&userHash=' +
                    userHash
                  "
                  target="_blank"
                >
                  <i class="el-icon-link"></i> Çelenk Atölyesi
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="logout">
                  <i class="el-icon-user"></i>
                  Çıkış
                </a>
              </li>
            </ul>
            <div class="mr-5">
              <el-date-picker
                v-model="raporDate"
                type="daterange"
                range-separator="-"
                start-placeholder="Başlama"
                end-placeholder="Bitiş"
                class="mr-1"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button @click="dateSearch()" type="primary">GETİR</el-button>
            </div>
            <div>
              <el-input
                placeholder="Sipariş Arama"
                v-model="keyword"
                prefix-icon="el-icon-search"
              >
                <el-button slot="append" @click="generalSearch()"
                  >ARA</el-button
                >
              </el-input>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <numbers-bar
      :bugunislembekleyenler_count="bugunislembekleyenler_count"
      :yarinislembekleyenler_count="yarinislembekleyenler_count"
      :bugunteslimedilecekler_count="bugunteslimedilecekler_count"
      :yarinteslimedilecekler_count="yarinteslimedilecekler_count"
      :tamamlanmayanlar_count="tamamlanmayanlar_count"
    ></numbers-bar>
    <div class="row mt-3">
      <div class="col-lg-6 col-12 text-center text-lg-left mb-2">
        <h3>
          <i class="el-icon-shopping-cart-full"></i> Bugüne İşlem Bekleyenler
        </h3>
      </div>
      <div class="col-lg-6 col-12 text-center text-lg-right mb-2">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button
            @click="filterCity('istanbul')"
            :class="
              this.orderType == 'istanbul' ? 'btn-warning' : 'btn-secondary'
            "
            type="button"
            class="btn"
          >
            Sadece İstanbul
          </button>
          <button
            @click="filterCity('hepsi')"
            :class="this.orderType == 'hepsi' ? 'btn-warning' : 'btn-secondary'"
            type="button"
            class="btn"
          >
            Tamamı
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <order-table
        :orders="orders"
        :userID="userID"
        :userHash="userHash"
      ></order-table>
    </div>
  </div>
</template>

<script>
import OrderTable from "../components/OrderTable.vue";
import NumbersBar from "../components/NumbersBar.vue";
export default {
  data: () => ({
    raporDate: "",
    userID: localStorage.getItem("userID"),
    userHash: localStorage.getItem("userHash"),
    orderType: "hepsi",
    keyword: "",
    search: "",
    bugunislembekleyenler_count: 0,
    yarinislembekleyenler_count: 0,
    bugunteslimedilecekler_count: 0,
    yarinteslimedilecekler_count: 0,
    tamamlanmayanlar_count: 0,
    orders: [],
    sites: [
      "harman.com.tr",
      "markacicek.com",
      "cicekatolyesi.com",
      "celenkatolyesi.com",
    ],
  }),
  components: {
    OrderTable,
    NumbersBar,
  },
  methods: {
    generalSearch() {
      this.$router.push({ name: "Search", query: { keyword: this.keyword } });
    },
    dateSearch() {
      this.$router.push({
        name: "DateSearch",
        query: { start: this.raporDate[0], end: this.raporDate[1] },
      });
    },
    logout() {
      localStorage.removeItem("userID");
      localStorage.removeItem("userHash");
      this.$router.push({ name: "Login" });
    },
    getOrders() {
      this.orders = [];
      this.bugunislembekleyenler_count = 0;
      this.yarinislembekleyenler_count = 0;
      this.bugunteslimedilecekler_count = 0;
      this.yarinteslimedilecekler_count = 0;
      this.tamamlanmayanlar_count = 0;
      Promise.all([
        this.sites.forEach((site) => {
          Promise.all([
            axios
              .get("https://" + site + "/panelapi/orderlist", {
                params: {
                  userID: this.userID,
                  userHash: this.userHash,
                  type: "bugunislembekleyenler",
                },
              })
              .then((response) => {
                this.bugunislembekleyenler_count =
                  this.bugunislembekleyenler_count +
                  response.data.counter.bugunislembekleyenler;
                this.yarinislembekleyenler_count =
                  this.yarinislembekleyenler_count +
                  response.data.counter.yarinislembekleyenler;
                this.bugunteslimedilecekler_count =
                  this.bugunteslimedilecekler_count +
                  response.data.counter.bugunteslimedilecekler;
                this.yarinteslimedilecekler_count =
                  this.yarinteslimedilecekler_count +
                  response.data.counter.yarinteslimedilecekler;
                this.tamamlanmayanlar_count =
                  this.tamamlanmayanlar_count +
                  response.data.counter.tamamlanmayanlar;
                this.orders = _.concat(this.orders, response.data.orders);
              }),
          ]).then(() => {
            this.orders = _.orderBy(this.orders, "tableOrder");
            if (this.orderType == "istanbul") {
              this.orders = _.filter(this.orders, function (o) {
                return (
                  o.alicisehir == "İSTANBUL ANADOLU" ||
                  o.alicisehir == "İSTANBUL AVRUPA"
                );
              });
            }
          });
        }),
      ]).then(() => {});
    },
    filterCity(type) {
      this.orderType = type;
      this.getOrders();
    },
    async userCheck() {
      await axios
        .get("https://cicekatolyesi.com/panelapi/userinfo", {
          params: {
            userID: this.userID,
            userHash: this.userHash,
          },
        })
        .then((response) => {
          var currentDate = this.$moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          var last_check = localStorage.getItem("last_check");
          if (response.data.sonuc == "success") {
            if (response.data.last_update >= last_check) {
              this.getOrders();
              console.log("Veri Güncellenmiş...");
              localStorage.setItem("last_check", currentDate);
            }
          } else {
            this.$router.push({ name: "Login" });
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getOrders();
    this.$nextTick(function () {
      window.setInterval(() => {
        this.userCheck();
      }, 15000);
    });
    var currentDate = this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem("last_check", currentDate);
  },
};
</script>