var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-bordered table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.orders),function(siparis){return _c('tr',{key:siparis.id,class:{
          'table-success': siparis.siparisdurumu == 'Teslim Edildi',
          'table-danger': siparis.siparisdurumu == 'Sipariş Alındı',
          'table-info': siparis.siparisdurumu == 'Hazırlanıyor',
          'table-warning': siparis.siparisdurumu == 'Gönderimde',
          'table-secondary': siparis.siparisdurumu == 'İptal',
        }},[_c('td',{staticClass:"text-center font-weight-bold"},[_c('br'),_c('br'),_c('img',{attrs:{"src":siparis.sitelogo,"alt":siparis.siparisno,"width":"80"}}),_c('br'),_c('br'),_vm._v(" "+_vm._s(siparis.siparisno)+" ")]),_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":siparis.urunlink,"target":"_blank"}},[_c('img',{staticClass:"img-thumbnail",attrs:{"src":siparis.urunresim,"alt":siparis.siparisno,"width":"60"}})]),_c('br'),_vm._v(" "+_vm._s(siparis.urunkodu)+" "),_c('br'),_c('small',{staticClass:"text-danger font-weight-bold text-uppercase"},[_vm._v(_vm._s(siparis.urunboyu))]),_c('br'),(siparis.filokodu)?_c('small',[_vm._v(" Filo Kodu: "),_c('strong',[_vm._v(_vm._s(siparis.filokodu))])]):_vm._e()]),_c('td',_vm._l((siparis.ekUrunveEkkartlar),function(ekler){return _c('span',{key:ekler.id},[_c('i',{staticClass:"el-icon-arrow-right"}),_vm._v(" "+_vm._s(ekler)+" "),_c('br')])}),0),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(siparis.odemetipi)+" "),_c('br'),_vm._v(" "+_vm._s(siparis.toplamtutar)+" TL "),_c('br'),_c('a',{staticClass:"badge",class:siparis.odemedurumu == 'Ödeme Yapıldı'
                ? 'badge-success'
                : 'badge-danger',attrs:{"href":siparis.payUrl,"target":"_blank"}},[_vm._v(_vm._s(siparis.odemedurumu))]),_c('br'),(siparis.odemenereye)?_c('small',[_vm._v(_vm._s(siparis.odemenereye))]):_vm._e(),_c('br'),_c('br'),_c('small',[_vm._v(" İşlem Zamanı "),_c('br'),_vm._v(" "+_vm._s(siparis.islemzamani)+" ")]),_c('br'),_c('el-tag',{attrs:{"type":"info","effect":"dark","size":"mini"}},[_c('i',{class:siparis.islemkaynagi == 'mobile'
                  ? 'el-icon-mobile'
                  : 'el-icon-monitor'}),_vm._v(" "+_vm._s(siparis.islemkaynagi)+" ")])],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(siparis.teslimattarihi,"DD.MM.YYYY"))+" "),_c('br'),_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v(_vm._s(siparis.teslimatsaati))]),_c('br'),_c('br'),_c('span',{class:siparis.teslimattipi == 'Normal Teslimat'
                ? 'text-info'
                : 'text-danger'},[_vm._v(_vm._s(siparis.teslimattipi))])]),_c('td',[_vm._v(" "+_vm._s(siparis.gondericiadi)+" "),_c('br'),_vm._v(" "+_vm._s(siparis.gondericitelefonu)+" "),_c('br'),_vm._v(" "+_vm._s(siparis.gondericiemail)+" "),_c('br'),(siparis.kuponkodu)?_c('small',{staticClass:"text-info"},[_vm._v("Kupon Kodu: "+_vm._s(siparis.kuponkodu))]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(siparis.aliciadi)+" "),_c('br'),_vm._v(" "+_vm._s(siparis.alicitelefonu)+" "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(siparis.alicisemt)+" "),_c('br'),_vm._v(" "+_vm._s(siparis.alicisehir)+" ")]),_c('br'),(siparis.siparisdurumu == 'Teslim Edildi')?_c('small',[(siparis.teslimedilen)?_c('span',[_vm._v(" Teslim Alan "),_c('br'),_vm._v(" "+_vm._s(siparis.teslimedilen)+" ")]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(siparis.teslimsaati)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(siparis.siparisdurumu)+" "),_c('br'),_c('br'),_c('el-tooltip',{attrs:{"content":"Sipariş Alındı","placement":"top"}},[_c('i',{staticClass:"el-icon-present siparisdurumuicon",class:siparis.siparisdurumu == 'Sipariş Alındı'
                  ? 'text-danger siparisdurumuiconactive'
                  : '',on:{"click":function($event){return _vm.siparisdurum(
                  'siparisalindi',
                  siparis.apiUrl,
                  siparis.siparisno
                )}}})]),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"Hazırlanıyor","placement":"top"}},[_c('i',{staticClass:"el-icon-time siparisdurumuicon",class:siparis.siparisdurumu == 'Hazırlanıyor'
                  ? 'text-primary siparisdurumuiconactive'
                  : '',on:{"click":function($event){return _vm.siparisdurum(
                  'hazirlaniyor',
                  siparis.apiUrl,
                  siparis.siparisno
                )}}})]),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"Gönderimde","placement":"top"}},[_c('i',{staticClass:"el-icon-truck siparisdurumuicon",class:siparis.siparisdurumu == 'Gönderimde'
                  ? 'text-warning siparisdurumuiconactive'
                  : '',on:{"click":function($event){return _vm.siparisdurum('gonderimde', siparis.apiUrl, siparis.siparisno)}}})]),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"Teslim Edildi","placement":"top"}},[_c('i',{staticClass:"el-icon-document-checked siparisdurumuicon",class:siparis.siparisdurumu == 'Teslim Edildi'
                  ? 'text-success siparisdurumuiconactive'
                  : '',on:{"click":function($event){return _vm.siparisdurum(
                  'teslimedildi',
                  siparis.apiUrl,
                  siparis.siparisno
                )}}})]),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"İptal","placement":"top"}},[_c('i',{staticClass:"el-icon-document-delete siparisdurumuicon",class:siparis.siparisdurumu == 'İptal'
                  ? 'text-danger siparisdurumuiconactive'
                  : '',on:{"click":function($event){return _vm.siparisdurum('iptal', siparis.apiUrl, siparis.siparisno)}}})])],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(siparis.dagitici)+" "),_c('br'),_vm._v(" "+_vm._s(siparis.takipno)+" ")]),_c('td',[(siparis.faturatercihi == 'efatura')?_c('span',{staticClass:"font-weight-bold text-uppercase text-danger text-center"},[_vm._v("FATURA İSTİYOR")]):_vm._e(),(siparis.invoice_status == '1')?_c('span',{staticClass:"font-weight-bold text-uppercase text-success text-center"},[_c('br'),_vm._v("FATURASI KESİLDİ ")]):_vm._e(),_vm._v(" "+_vm._s(siparis.not)+" ")]),_c('td',{staticStyle:{"word-wrap":"break-word","min-width":"130px","max-width":"130px"}},[_c('el-tooltip',{attrs:{"content":"Sipariş Formu Yazdır","placement":"left"}},[_c('a',{attrs:{"href":'https://' +
                siparis.apiUrl +
                '/yonetim/siparisformu.php?id=' +
                siparis.siparisno +
                '&userID=' +
                _vm.userID +
                '&userHash=' +
                _vm.userHash,"target":"_blank"}},[_c('i',{staticClass:"el-icon-printer"}),_vm._v("Sipariş Formu ")])]),_c('br'),_c('el-tooltip',{attrs:{"content":"Siparişi Düzenle","placement":"left"}},[_c('a',{on:{"click":function($event){return _vm.orderEdit(siparis.siparisno, siparis.apiUrl)}}},[_c('i',{staticClass:"el-icon-edit-outline"}),_vm._v("Düzenle ")])]),(siparis.faturatercihi == 'efatura')?_c('el-tooltip',{attrs:{"content":"Fatura Oluştur","placement":"left"}},[_c('a',{on:{"click":function($event){return _vm.sendInvoice(siparis.apiUrl, siparis.siparisno)}}},[_c('br'),_c('i',{staticClass:"el-icon-document"}),_vm._v("Fatura ")])]):_vm._e(),_c('br'),_c('el-tooltip',{attrs:{"content":"Sipariş Hareketleri","placement":"left"}},[_c('a',{class:siparis.orderAction > 0 ? 'text-danger' : '',on:{"click":function($event){return _vm.orderActions(siparis.siparisno, siparis.apiUrl)}}},[_c('i',{staticClass:"el-icon-folder-opened"}),_vm._v("Hareketler "),(siparis.orderAction > 0)?_c('span',[_vm._v("["+_vm._s(siparis.orderAction)+"]")]):_vm._e()])]),_c('br'),_c('el-tooltip',{attrs:{"content":"Siparişi Sil","placement":"left"}},[_c('a',{on:{"click":function($event){return _vm.siparisdurum('silindi', siparis.apiUrl, siparis.siparisno)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v("Sil ")])]),_c('br'),_c('el-tooltip',{attrs:{"content":"Filo Gönder","placement":"left"}},[(siparis.filokodu)?_c('a',{on:{"click":function($event){return _vm.sendFilo(siparis.apiUrl, siparis.siparisno)}}},[_c('i',{staticClass:"el-icon-position"}),_vm._v("Filo Gönder ")]):_vm._e()])],1)])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Site/No")]),_c('th',[_vm._v("Ürün Bilgileri")]),_c('th',[_vm._v("Ek Ürün ve Ek Kart")]),_c('th',[_vm._v("Ödeme Bilgileri")]),_c('th',[_vm._v("Teslimat Bilgileri")]),_c('th',[_vm._v("Gönderici Bilgileri")]),_c('th',[_vm._v("Alıcı Bilgileri")]),_c('th',[_vm._v("Sipariş Durumu")]),_c('th',[_vm._v("Dağıtıcı")]),_c('th',[_vm._v("Notlar")]),_c('th',[_vm._v("İşlemler")])])])}]

export { render, staticRenderFns }