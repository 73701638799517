import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Search from "../views/Search.vue";
import DateSearch from "../views/DateSearch.vue";

import ActionWaitingLater from "../views/ActionWaitingLater.vue";
import ActionWaitingToday from "../views/ActionWaitingToday.vue";
import DeliveryWaitingLater from "../views/DeliveryWaitingLater.vue";
import DeliveryWaitingToday from "../views/DeliveryWaitingToday.vue";
import IncompleteOrders from "../views/IncompleteOrders.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/report",
    name: "DateSearch",
    component: DateSearch,
  },
  {
    path: "/yarin-ve-sonrasi-islem-bekleyenler",
    name: "ActionWaitingLater",
    component: ActionWaitingLater,
  },
  {
    path: "/bugun-islem-bekleyenler",
    name: "ActionWaitingToday",
    component: ActionWaitingToday,
  },
  {
    path: "/yarin-ve-sonrasi-teslim-edilecekler",
    name: "DeliveryWaitingLater",
    component: DeliveryWaitingLater,
  },
  {
    path: "/bugun-teslim-edilecekler",
    name: "DeliveryWaitingToday",
    component: DeliveryWaitingToday,
  },
  {
    path: "/tamamlanmayan-siparisler",
    name: "IncompleteOrders",
    component: IncompleteOrders,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
