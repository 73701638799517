window._ = require("lodash");
window.axios = require("axios");

import "bootstrap/dist/css/bootstrap.min.css";
import "element-ui/lib/theme-chalk/index.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/tr-TR";
import VModal from "vue-js-modal";

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    clickToClose: false,
    height: "auto",
    width: "700",
    scrollable: true,
  },
});
Vue.use(require("vue-moment"));

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
