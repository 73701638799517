<template>
  <div class="container">
    <div class="row mt-3 mb-4">
      <div class="col-8">
        <h3>
          <i class="el-icon-edit"></i>
          Sipariş Düzenle - {{ orderDetail.id }}
        </h3>
      </div>
      <div class="col-4 text-right">
        <button type="button" class="btn btn-sm" aria-label="Close" @click="$emit('close')">
          <i class="el-icon-close"></i>
        </button>
      </div>
    </div>
    <el-form ref="orderDetail" :model="orderDetail" label-position="top" size="small">
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="İşlem Detayları">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Üye No">
                <el-input v-model="orderDetail.uyeid" placeholder="Üye No"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Kullanılan Kupon Kodu">
                <el-input
                  v-model="orderDetail.kullanilankuponkodu"
                  placeholder="Kullanılan Kupon Kodu"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Ödeme Tipi">
                <el-select v-model="orderDetail.odemetipi" placeholder="Ödeme Tipi">
                  <el-option label="Cari Hesap" value="carihesap"></el-option>
                  <el-option label="GarantiPay" value="garantipay"></el-option>
                  <el-option label="Sanal Pos" value="kredikarti"></el-option>
                  <el-option label="3D Pos" value="3dcreditcard"></el-option>
                  <el-option label="Havale/EFT" value="havaleeft"></el-option>
                  <el-option label="Mail Order" value="telefon"></el-option>
                  <el-option label="PayPal" value="paypal"></el-option>
                  <el-option label="BKM Ekpress" value="bkm"></el-option>
                  <el-option label="Hediye Çeki" value="hediyeceki"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Ödeme Durumu">
                <el-select v-model="orderDetail.odemedurumu" placeholder="Ödeme Durumu">
                  <el-option label="ÖDEME YAPILDI" value="odemeyapildi"></el-option>
                  <el-option label="ÖDEME BEKLENİYOR" value="odemebekleniyor"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Ödeme Nereye?">
                <el-input v-model="orderDetail.odemenereye" placeholder="Ödeme Nereye Yapıldı?"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Sipariş Durumu">
                <el-select v-model="orderDetail.siparisdurumu" placeholder="Sipariş Durumu">
                  <el-option label="Tamamlanmadı" value="tamamlanmadi"></el-option>
                  <el-option label="Sipariş Alındı" value="siparisalindi"></el-option>
                  <el-option label="Hazırlanıyor" value="hazirlaniyor"></el-option>
                  <el-option label="Gönderimde" value="gonderimde"></el-option>
                  <el-option label="Teslim Edildi" value="teslimedildi"></el-option>
                  <el-option label="İptal" value="iptal"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Dağıtıcı">
                <el-select v-model="orderDetail.dagitici" placeholder="Dağıtıcı">
                  <el-option label="Seçiniz" :value="0"></el-option>
                  <el-option
                    v-for="kurye in kuryeler"
                    :key="kurye.id"
                    :label="kurye.adi"
                    :value="kurye.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Filo Takip No">
                <el-input v-model="orderDetail.takipno" placeholder="Filo Takip No"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Not">
            <el-input v-model="orderDetail.markanot"></el-input>
          </el-form-item>
          <el-form-item label="Filo Bayisine Not">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Filo Bayisine Not"
              v-model="orderDetail.filonot"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="Ürün Detayları">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Ürün">
                <el-input v-model="orderDetail.urunid" placeholder="Ürün"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Ürün Boyu">
                <el-select v-model="orderDetail.urunboy" placeholder="Ürün Boyu">
                  <el-option label="Normal Boy" value="normalboy"></el-option>
                  <el-option label="Büyük Boy" value="buyukboy"></el-option>
                  <el-option label="Küçük Boy" value="kucukboy"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Ek Ürünler">
            <el-input v-model="orderDetail.ekurunler" placeholder="Ek Ürünler"></el-input>
          </el-form-item>
          <el-form-item label="Özel Kartlar">
            <el-input v-model="orderDetail.ozelkartlar" placeholder="Özel Kartlar"></el-input>
          </el-form-item>
          <el-form-item label="Ürün İle İlgili Not">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Ürün İle İlgili Not"
              v-model="orderDetail.saticiyanot"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="Gönderici Detayları">
          <el-form-item label="Gönderen Adı Soyadı">
            <el-input v-model="orderDetail.adisoyadi" placeholder="Gönderen Adı Soyadı"></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Gönderen Telefon">
                <el-input v-model="orderDetail.telno" placeholder="Gönderen Telefon"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Gönderen E-Mail">
                <el-input v-model="orderDetail.email" placeholder="Gönderen E-Mail"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Kart Mesajı">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Kart Mesajı"
              v-model="orderDetail.kartmesaji"
            ></el-input>
          </el-form-item>
          <el-form-item label="Karta Yazılacak İsim">
            <el-input v-model="orderDetail.kartayazilacakisim" placeholder="Karta Yazılacak İsim"></el-input>
          </el-form-item>
          <el-form-item label="Teslimat Bilgilendirme İstiyor mu?">
            <el-select
              v-model="orderDetail.bilgilendirmeistiyor"
              placeholder="Teslimat Bilgilendirme İstiyor mu?"
            >
              <el-option label="Evet" value="yes"></el-option>
              <el-option label="Hayır" value="no"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="Alıcı Detayları">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Alıcı Adı Soyadı">
                <el-input v-model="orderDetail.aliciadisoyadi" placeholder="Alıcı Adı Soyadı"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Alıcı Telefon">
                <el-input v-model="orderDetail.alicitelno" placeholder="Alıcı Telefon"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Gönderilecek Yer">
                <el-input v-model="orderDetail.gonderilecekyer" placeholder="Gönderilecek Yer"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Gönderilecek Yer Adı">
                <el-input
                  v-model="orderDetail.gonderilecekyeradi"
                  placeholder="Gönderilecek Yer Adı"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Alıcı Adres">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Alıcı Adres"
              v-model="orderDetail.aliciadres"
            ></el-input>
          </el-form-item>
          <el-form-item label="Alıcı Lokasyon">
            <el-input v-model="orderDetail.alicilokasyon" placeholder="Alıcı Lokasyon"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="Fatura Detayları">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Fatura Tercihi">
                <el-select v-model="orderDetail.faturatipi" placeholder="Fatura Tercihi">
                  <el-option label="Bireysel Fatura" value="bireysel"></el-option>
                  <el-option label="Kurumsal Fatura" value="kurumsal"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Fatura Teslim Şekli">
                <el-select
                  v-model="orderDetail.faturateslimsekli"
                  placeholder="Fatura Teslim Şekli"
                >
                  <el-option label="Fatura İstemiyor" value="ofis"></el-option>
                  <el-option label="Fatura İstiyor" value="efatura"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Vergi Dairesi">
                <el-input v-model="orderDetail.vergidairesi" placeholder="Vergi Dairesi"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Vergi / T.C. Kimlik No">
                <el-input v-model="orderDetail.vergino" placeholder="Vergi / T.C. Kimlik No"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Şirket / Kurum Ünvanı">
            <el-input v-model="orderDetail.sirket" placeholder="Şirket / Kurum Ünvanı"></el-input>
          </el-form-item>
          <el-form-item label="Fatura Adres">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Fatura Adres"
              v-model="orderDetail.adres"
            ></el-input>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Fatura Şehir">
                <el-select
                  v-model="orderDetail.invoice_zone_id"
                  placeholder="Fatura Şehir"
                  @change="getInvoiceCities"
                >
                  <el-option label="Seçiniz" :value="0"></el-option>
                  <el-option
                    v-for="zone in invoice_zones"
                    :key="zone.id"
                    :label="zone.name"
                    :value="zone.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Fatura İlçe">
                <el-select v-model="orderDetail.invoice_city_id" placeholder="Fatura İlçe">
                  <el-option label="Seçiniz" :value="0"></el-option>
                  <el-option
                    v-for="city in invoice_cities"
                    :key="city.id"
                    :label="city.name"
                    :value="city.id"
                    :selected="orderDetail.invoice_city_id == city.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="6">
            <el-col :span="8">
              <el-form-item label="Fatura Durumu">
                <el-select v-model="orderDetail.invoice_status" placeholder="Fatura Durumu">
                  <el-option label="Fatura Kesildi" :value="1"></el-option>
                  <el-option label="Fatura Kesilmedi" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Fatura Tarihi">
                <el-date-picker
                  v-model="orderDetail.invoice_date"
                  type="datetime"
                  placeholder="Fatura Tarihi"
                  format="dd.MM.yyyy HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Fatura No">
                <el-input v-model="orderDetail.invoice_no" placeholder="Fatura No"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Ücret Detayları">
          <el-form-item label="Ürün Bedeli">
            <el-input placeholder="Ürün Bedeli" v-model="orderDetail.urunbedeli">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Kullanılan Kupon İndirimi">
            <el-input
              placeholder="Kullanılan Kupon İndirimi"
              v-model="orderDetail.kullanilankuponindirimi"
            >
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Yol Ücreti">
            <el-input placeholder="Yol Ücreti" v-model="orderDetail.yolucreti">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Ek Ürün Ücreti">
            <el-input placeholder="Ek Ürün Ücreti" v-model="orderDetail.ekurunucreti">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Özel Kart Ücreti">
            <el-input placeholder="Özel Kart Ücreti" v-model="orderDetail.ozelkartucreti">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Alt Toplam">
            <el-input placeholder="Alt Toplam" v-model="orderDetail.alttoplam">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Toplam KDV">
            <el-input placeholder="Toplam KDV" v-model="orderDetail.toplamkdv">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Ödenecek Tutar">
            <el-input placeholder="Ödenecek Tutar" v-model="orderDetail.odenecektutar">
              <template slot="append">TL</template>
            </el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="Teslimat Detayları">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Teslimat Tarihi">
                <el-date-picker
                  v-model="orderDetail.teslimattarihi"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Teslimat Tarihi"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Teslimat Saati">
                <el-input v-model="orderDetail.teslimatsaati" placeholder="Teslimat Saati"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Teslim Edildiği Zaman">
                <el-date-picker
                  v-model="orderDetail.teslimedildigizaman"
                  type="datetime"
                  format="dd.MM.yyyy HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Teslim Edildiği Zaman"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Teslim Alan">
                <el-input v-model="orderDetail.teslimedilen" placeholder="Teslim Alan"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Teslim Bilgilendirme Yapıldı mı?">
            <el-select
              v-model="orderDetail.teslimbilgilendirmedurumu"
              placeholder="Teslim Bilgilendirme Yapıldı mı?"
            >
              <el-option label="Evet" value="yes"></el-option>
              <el-option label="Hayır" value="no"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <el-row class="mt-3 mb-2 text-right">
      <el-button @click="$emit('close')" size="small">Vazgeç</el-button>
      <el-button @click="orderUpdate" type="primary" size="small">KAYDET</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    tabPosition: "left",
    userID: localStorage.getItem("userID"),
    userHash: localStorage.getItem("userHash"),
    orderDetail: {},
    kuryeler: [],
    invoice_zones: [],
    invoice_cities: [],
    pickerOptions: {
      firstDayOfWeek: 1
    }
  }),
  props: ["orderID", "orderUrl"],
  methods: {
    async orderUpdate() {
      const loading = this.$loading({
        lock: true,
        text: "Bekleyiniz...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)"
      });
      await axios
        .post(
          "https://" +
            this.$props.orderUrl +
            "/panelapi/orderupdate?userID=" +
            this.userID +
            "&userHash=" +
            this.userHash,
          this.orderDetail
        )
        .then(response => {
          if (response.data.sonuc === "success") {
            this.$message({
              type: "success",
              message: "Sipariş başarıyla güncellendi!",
              duration: 1000,
              onClose: () => {
                window.location.reload();
              }
            });
          } else {
            this.$message({
              type: "error",
              message: response.data.msg
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "Hata oluştu. Yeniden deneyiniz."
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    async getOrderDetail() {
      await axios
        .get("https://" + this.$props.orderUrl + "/panelapi/orderdetail", {
          params: {
            userID: this.userID,
            userHash: this.userHash,
            orderID: this.$props.orderID
          }
        })
        .then(response => {
          this.orderDetail = response.data;
          this.getInvoiceCities();
        });
    },
    async getKuryeler() {
      await axios
        .get("https://cicekatolyesi.com/panelapi/kuryeler", {
          params: {
            userID: this.userID,
            userHash: this.userHash
          }
        })
        .then(response => {
          this.kuryeler = response.data;
        });
    },
    async getInvoiceZones() {
      await axios
        .get("https://cicekatolyesi.com/api/invoicezones")
        .then(response => {
          this.invoice_zones = response.data;
        });
    },
    async getInvoiceCities() {
      this.invoice_cities = [];
      await axios
        .get("https://cicekatolyesi.com/api/invoicecities", {
          params: {
            q: this.orderDetail.invoice_zone_id
          }
        })
        .then(response => {
          this.invoice_cities = response.data;
        });
    }
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: "Yükleniyor",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.5)"
    });
    Promise.all([
      this.getOrderDetail(),
      this.getKuryeler(),
      this.getInvoiceZones()
    ]).then(() => {
      loading.close();
    });
  }
};
</script>

<style>
.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  line-height: 12px;
  margin-bottom: 0 !important;
}
</style>