<template>
  <div class="row homeNumberBar">
    <div class="col-lg-2 col-6 header-box bg-secondary">
      <router-link :to="{ name: 'Dashboard' }">
        <div>
          <span class="number">
            <i class="el-icon-s-home"></i>
          </span>
          <br />Bugünün Siparişleri
        </div>
      </router-link>
    </div>
    <div class="col-lg-2 col-6 header-box bg-info">
      <router-link :to="{ name: 'ActionWaitingToday' }">
        <div>
          <span class="number">{{ bugunislembekleyenler_count }}</span>
          <br />Bugüne İşlem Bekleyenler
        </div>
      </router-link>
    </div>
    <div class="col-lg-2 col-6 header-box bg-primary">
      <router-link :to="{ name: 'ActionWaitingLater' }">
        <div>
          <span class="number">{{ yarinislembekleyenler_count }}</span>
          <br />Yarın ve Sonrası İşlem Bekleyenler
        </div>
      </router-link>
    </div>
    <div class="col-lg-2 col-6 header-box bg-warning">
      <router-link :to="{ name: 'DeliveryWaitingToday' }">
        <div>
          <span class="number">{{ bugunteslimedilecekler_count }}</span>
          <br />Bugün Teslim Edilecekler
        </div>
      </router-link>
    </div>
    <div class="col-lg-2 col-6 header-box bg-success">
      <router-link :to="{ name: 'DeliveryWaitingLater' }">
        <div>
          <span class="number">{{ yarinteslimedilecekler_count }}</span>
          <br />Yarın ve Sonrası Teslimler
        </div>
      </router-link>
    </div>
    <div class="col-lg-2 col-6 header-box bg-danger">
      <router-link :to="{ name: 'IncompleteOrders' }">
        <div>
          <span class="number">{{ tamamlanmayanlar_count }}</span>
          <br />Tamamlanmayan Siparişler
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "bugunislembekleyenler_count",
    "yarinislembekleyenler_count",
    "bugunteslimedilecekler_count",
    "yarinteslimedilecekler_count",
    "tamamlanmayanlar_count"
  ]
};
</script>

<style>
</style>