<template>
  <div class="table-bordered table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Site/No</th>
          <th>Ürün Bilgileri</th>
          <th>Ek Ürün ve Ek Kart</th>
          <th>Ödeme Bilgileri</th>
          <th>Teslimat Bilgileri</th>
          <th>Gönderici Bilgileri</th>
          <th>Alıcı Bilgileri</th>
          <th>Sipariş Durumu</th>
          <th>Dağıtıcı</th>
          <th>Notlar</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="siparis in orders"
          :key="siparis.id"
          :class="{
            'table-success': siparis.siparisdurumu == 'Teslim Edildi',
            'table-danger': siparis.siparisdurumu == 'Sipariş Alındı',
            'table-info': siparis.siparisdurumu == 'Hazırlanıyor',
            'table-warning': siparis.siparisdurumu == 'Gönderimde',
            'table-secondary': siparis.siparisdurumu == 'İptal',
          }"
        >
          <td class="text-center font-weight-bold">
            <br />
            <br />
            <img :src="siparis.sitelogo" :alt="siparis.siparisno" width="80" />
            <br />
            <br />
            {{ siparis.siparisno }}
          </td>
          <td class="text-center">
            <a :href="siparis.urunlink" target="_blank">
              <img
                :src="siparis.urunresim"
                :alt="siparis.siparisno"
                width="60"
                class="img-thumbnail"
              />
            </a>
            <br />
            {{ siparis.urunkodu }}
            <br />
            <small class="text-danger font-weight-bold text-uppercase">{{
              siparis.urunboyu
            }}</small>
            <br />
            <small v-if="siparis.filokodu">
              Filo Kodu:
              <strong>{{ siparis.filokodu }}</strong>
            </small>
          </td>
          <td>
            <span v-for="ekler in siparis.ekUrunveEkkartlar" :key="ekler.id">
              <i class="el-icon-arrow-right"></i>
              {{ ekler }}
              <br />
            </span>
          </td>
          <td class="text-center">
            {{ siparis.odemetipi }}
            <br />
            {{ siparis.toplamtutar }} TL
            <br />
            <a
              :class="
                siparis.odemedurumu == 'Ödeme Yapıldı'
                  ? 'badge-success'
                  : 'badge-danger'
              "
              :href="siparis.payUrl"
              target="_blank"
              class="badge"
              >{{ siparis.odemedurumu }}</a
            >
            <br />
            <small v-if="siparis.odemenereye">{{ siparis.odemenereye }}</small>
            <br />
            <br />
            <small>
              İşlem Zamanı
              <br />
              {{ siparis.islemzamani }}
            </small>
            <br />
            <el-tag type="info" effect="dark" size="mini">
              <i
                :class="
                  siparis.islemkaynagi == 'mobile'
                    ? 'el-icon-mobile'
                    : 'el-icon-monitor'
                "
              ></i>
              {{ siparis.islemkaynagi }}
            </el-tag>
          </td>
          <td class="text-center">
            {{ siparis.teslimattarihi | moment("DD.MM.YYYY") }}
            <br />
            <span class="text-danger font-weight-bold">{{
              siparis.teslimatsaati
            }}</span>
            <br />
            <br />
            <span
              :class="
                siparis.teslimattipi == 'Normal Teslimat'
                  ? 'text-info'
                  : 'text-danger'
              "
              >{{ siparis.teslimattipi }}</span
            >
          </td>
          <td>
            {{ siparis.gondericiadi }}
            <br />
            {{ siparis.gondericitelefonu }}
            <br />
            {{ siparis.gondericiemail }}
            <br />
            <small class="text-info" v-if="siparis.kuponkodu"
              >Kupon Kodu: {{ siparis.kuponkodu }}</small
            >
          </td>
          <td>
            {{ siparis.aliciadi }}
            <br />
            {{ siparis.alicitelefonu }}
            <br />
            <strong>
              {{ siparis.alicisemt }}
              <br />
              {{ siparis.alicisehir }}
            </strong>
            <br />
            <small v-if="siparis.siparisdurumu == 'Teslim Edildi'">
              <span v-if="siparis.teslimedilen">
                Teslim Alan
                <br />
                {{ siparis.teslimedilen }}
              </span>
              <br />
              {{ siparis.teslimsaati }}
            </small>
          </td>
          <td class="text-center">
            {{ siparis.siparisdurumu }}
            <br />
            <br />
            <el-tooltip content="Sipariş Alındı" placement="top">
              <i
                @click="
                  siparisdurum(
                    'siparisalindi',
                    siparis.apiUrl,
                    siparis.siparisno
                  )
                "
                :class="
                  siparis.siparisdurumu == 'Sipariş Alındı'
                    ? 'text-danger siparisdurumuiconactive'
                    : ''
                "
                class="el-icon-present siparisdurumuicon"
              ></i> </el-tooltip
            >&nbsp;
            <el-tooltip content="Hazırlanıyor" placement="top">
              <i
                @click="
                  siparisdurum(
                    'hazirlaniyor',
                    siparis.apiUrl,
                    siparis.siparisno
                  )
                "
                :class="
                  siparis.siparisdurumu == 'Hazırlanıyor'
                    ? 'text-primary siparisdurumuiconactive'
                    : ''
                "
                class="el-icon-time siparisdurumuicon"
              ></i> </el-tooltip
            >&nbsp;
            <el-tooltip content="Gönderimde" placement="top">
              <i
                @click="
                  siparisdurum('gonderimde', siparis.apiUrl, siparis.siparisno)
                "
                :class="
                  siparis.siparisdurumu == 'Gönderimde'
                    ? 'text-warning siparisdurumuiconactive'
                    : ''
                "
                class="el-icon-truck siparisdurumuicon"
              ></i> </el-tooltip
            >&nbsp;
            <el-tooltip content="Teslim Edildi" placement="top">
              <i
                @click="
                  siparisdurum(
                    'teslimedildi',
                    siparis.apiUrl,
                    siparis.siparisno
                  )
                "
                :class="
                  siparis.siparisdurumu == 'Teslim Edildi'
                    ? 'text-success siparisdurumuiconactive'
                    : ''
                "
                class="el-icon-document-checked siparisdurumuicon"
              ></i> </el-tooltip
            >&nbsp;
            <el-tooltip content="İptal" placement="top">
              <i
                @click="
                  siparisdurum('iptal', siparis.apiUrl, siparis.siparisno)
                "
                :class="
                  siparis.siparisdurumu == 'İptal'
                    ? 'text-danger siparisdurumuiconactive'
                    : ''
                "
                class="el-icon-document-delete siparisdurumuicon"
              ></i>
            </el-tooltip>
          </td>
          <td class="text-center">
            {{ siparis.dagitici }}
            <br />
            {{ siparis.takipno }}
          </td>
          <td>
            <span
              class="font-weight-bold text-uppercase text-danger text-center"
              v-if="siparis.faturatercihi == 'efatura'"
              >FATURA İSTİYOR</span
            >
            <span
              class="font-weight-bold text-uppercase text-success text-center"
              v-if="siparis.invoice_status == '1'"
            >
              <br />FATURASI KESİLDİ
            </span>
            {{ siparis.not }}
          </td>
          <td style="word-wrap: break-word; min-width: 130px; max-width: 130px">
            <el-tooltip content="Sipariş Formu Yazdır" placement="left">
              <a
                :href="
                  'https://' +
                  siparis.apiUrl +
                  '/yonetim/siparisformu.php?id=' +
                  siparis.siparisno +
                  '&userID=' +
                  userID +
                  '&userHash=' +
                  userHash
                "
                target="_blank"
              >
                <i class="el-icon-printer"></i>Sipariş Formu
              </a>
            </el-tooltip>
            <br />
            <el-tooltip content="Siparişi Düzenle" placement="left">
              <a @click="orderEdit(siparis.siparisno, siparis.apiUrl)">
                <i class="el-icon-edit-outline"></i>Düzenle
              </a>
            </el-tooltip>
            <el-tooltip
              content="Fatura Oluştur"
              placement="left"
              v-if="siparis.faturatercihi == 'efatura'"
            >
              <a @click="sendInvoice(siparis.apiUrl, siparis.siparisno)">
                <br />
                <i class="el-icon-document"></i>Fatura
              </a>
            </el-tooltip>
            <br />
            <el-tooltip content="Sipariş Hareketleri" placement="left">
              <a
                @click="orderActions(siparis.siparisno, siparis.apiUrl)"
                :class="siparis.orderAction > 0 ? 'text-danger' : ''"
              >
                <i class="el-icon-folder-opened"></i>Hareketler
                <span v-if="siparis.orderAction > 0"
                  >[{{ siparis.orderAction }}]</span
                >
              </a>
            </el-tooltip>
            <br />
            <el-tooltip content="Siparişi Sil" placement="left">
              <a
                @click="
                  siparisdurum('silindi', siparis.apiUrl, siparis.siparisno)
                "
              >
                <i class="el-icon-delete"></i>Sil
              </a>
            </el-tooltip>
            <br />
            <el-tooltip content="Filo Gönder" placement="left">
              <a
                v-if="siparis.filokodu"
                @click="sendFilo(siparis.apiUrl, siparis.siparisno)"
              >
                <i class="el-icon-position"></i>Filo Gönder
              </a>
            </el-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import OrderActions from "../components/OrderActions.vue";
import OrderEdit from "../components/OrderEdit.vue";
export default {
  props: ["orders", "userID", "userHash"],
  methods: {
    orderActions(order_id, api_url) {
      this.$modal.show(OrderActions, {
        orderID: order_id,
        orderUrl: api_url,
      });
    },
    orderEdit(order_id, api_url) {
      this.$modal.show(OrderEdit, {
        orderID: order_id,
        orderUrl: api_url,
      });
    },
    siparisdurum(status, url, order_id) {
      this.$confirm(
        "Siparişin durumu " +
          status +
          " olarak güncellenecektir. Devam etmek istiyor musunuz?",
        "Sipariş Durumu Güncelleme",
        {
          confirmButtonText: "GÜNCELLE",
          cancelButtonText: "Vazgeç",
          type: "warning",
        }
      )
        .then(() => {
          Promise.all([
            axios
              .get("https://" + url + "/panelapi/siparisdurumdegis", {
                params: {
                  userID: this.userID,
                  userHash: this.userHash,
                  siparisno: order_id,
                  durum: status,
                },
              })
              .then((response) => {
                if (response.data.sonuc == "success") {
                  this.$message({
                    type: "success",
                    message: "Sipariş durumu güncellendi.",
                    duration: 1000,
                    onClose: () => {
                      window.location.reload();
                    },
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: "Hata oluştu. Yeniden deneyiniz.",
                  });
                }
              }),
          ]);
        })
        .catch(() => {});
    },
    sendFilo(url, order_id) {
      Promise.all([
        axios
          .get("https://" + url + "/panelapi/orderdetail", {
            params: {
              userID: this.userID,
              userHash: this.userHash,
              orderID: order_id,
            },
          })
          .then((response) => {
            const onaymesaj =
              "<strong>Filo Ürün Kodu:</strong> " +
              response.data.urunfilokodu +
              "<br/><strong>Şehir:</strong> " +
              response.data.alicisemt +
              " / " +
              response.data.alicisehir +
              "<br/><strong>Teslimat Zamanı:</strong> " +
              response.data.teslimattarihi +
              " / " +
              response.data.teslimatsaati +
              "<br/><br/><strong>Alıcı Adı Soyadı:</strong> " +
              response.data.aliciadisoyadi +
              "<br/><strong>Alıcı Telefon:</strong> " +
              response.data.alicitelno +
              "<br/><strong>Gönderilecek Yer:</strong> " +
              response.data.gonderilecekyer +
              "<br/><strong>Gönderilecek Yer Adı:</strong> " +
              response.data.gonderilecekyeradi +
              "<br/><strong>Gönderilecek Adres:</strong> " +
              response.data.aliciadres +
              "<br/><br/><strong>Kart Mesajı:</strong> " +
              response.data.kartmesaji +
              "<br/><strong>Karta Yazılacak İsim:</strong> " +
              response.data.kartayazilacakisim +
              "<br/><br/><strong>Bayiye Not:</strong> " +
              response.data.filonot;
            this.$confirm(onaymesaj, "Siparişi Filo'ya Gönder?", {
              confirmButtonText: "GÖNDER",
              cancelButtonText: "Vazgeç",
              dangerouslyUseHTMLString: true,
            })
              .then(() => {
                Promise.all([
                  axios
                    .get("https://" + url + "/panelapi/filopost", {
                      params: {
                        userID: this.userID,
                        userHash: this.userHash,
                        orderID: order_id,
                      },
                    })
                    .then((response) => {
                      if (response.data.sonuc == "success") {
                        this.$message({
                          dangerouslyUseHTMLString: true,
                          type: "success",
                          message:
                            "Sipariş başarıyla gönderildi.<br/>Filo Sipariş No: <strong>" +
                            response.data.filosiparisno +
                            "</strong>",
                          onClose: () => {
                            window.location.reload();
                          },
                        });
                      } else {
                        this.$message({
                          type: "error",
                          message: response.data.msg,
                        });
                      }
                    }),
                ]);
              })
              .catch(() => {});
          }),
      ]);
    },
    sendInvoice(url, order_id) {
      Promise.all([
        axios
          .get("https://" + url + "/panelapi/orderinfoinvoice", {
            params: {
              userID: this.userID,
              userHash: this.userHash,
              orderID: order_id,
            },
          })
          .then((response) => {
            if (response.data.sonuc == "ok") {
              const onaymesaj =
                "<strong>Sipariş No:</strong> " +
                response.data.order.siparisno +
                "<br/><strong>Fatura Tercihi:</strong> " +
                response.data.order.faturatipi +
                "<br/><strong>Vergi Dairesi:</strong> " +
                response.data.order.vergidairesi +
                "<br/><strong>Vergi / T.C. Kimlik No:</strong> " +
                response.data.order.vergino +
                "<br/><strong>Şirket / Kurum Ünvanı:</strong> " +
                response.data.order.faturaunvan +
                "<br/><strong>Fatura Adres:</strong> " +
                response.data.order.adres +
                "<br/><strong>Fatura İlçe:</strong> " +
                response.data.order.ilce +
                "<br/><strong>Fatura Şehir:</strong> " +
                response.data.order.sehir;
              this.$confirm(onaymesaj, "e-Arşiv / e-Fatura Gönder", {
                confirmButtonText: "FATURA GÖNDER",
                cancelButtonText: "Vazgeç",
                dangerouslyUseHTMLString: true,
              })
                .then(() => {
                  const loading = this.$loading({
                    lock: true,
                    text: "Bekleyiniz",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.5)",
                  });
                  Promise.all([
                    axios
                      .get("https://" + url + "/panelapi/sendinvoice", {
                        params: {
                          userID: this.userID,
                          userHash: this.userHash,
                          orderID: order_id,
                        },
                      })
                      .then((response) => {
                        console.log(response.data);
                        if (response.data.sonuc == "success") {
                          this.$message({
                            type: "success",
                            message:
                              "Sipariş faturası başarıyla gönderildi. " +
                              response.data.msg,
                            onClose: () => {
                              window.location.reload();
                            },
                          });
                        } else {
                          this.$message({
                            type: "error",
                            message: response.data.msg,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .finally(() => {
                        loading.close();
                      }),
                  ]);
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              this.$message({
                type: "error",
                message: response.data.msg,
              });
            }
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
          }),
      ]);
    },
  },
};
</script>

<style>
</style>